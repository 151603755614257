<template>
  <div class="card-optnd p-0"> 
  <div class="card-partners"> 
    <b-table
      id="partners-table"
      class="table-optnd table-partners"
      ref="brazilianCompaniesPartnersTable"
      :items="tableItems"
      :fields="fields"      
      :per-page="perPage"
      :current-page="currentPage"  
      empty-text="Nenhum sócio encontrado"
      show-empty      
      striped>

        <template #table-colgroup="scope">
            <col v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'detalhes' ? '24%' : ''}" >
        </template>

        <template v-slot:head(nome)="data">
            <span v-html="data.label"></span>
        </template>

        <template v-slot:cell(nome)="data">
            <p class="mb-0 font-weight-500 text-dark">{{ data.item.nome | capitalize }}</p>
            <p class="mb-0 mt-1">
                <span class="mr-2" v-if="data.item.cnpj_cpf.length == 14">
                    {{ data.item.cnpj_cpf | fix-cnpj }}
                </span>
                <span class="mr-2" v-else>
                    {{ data.item.cnpj_cpf | fix-cpf }}
                </span>

                <span class="badge-yellow height-22 mr-2 mb-1" title="Código do país de origem" v-if="data.item.pais_ext">
                    Estrangeiro
                </span>

                <span v-if="data.item.tipo == 'Pessoa Jurídica'" class="badge-pink height-22 mb-1">{{ data.item.tipo }}</span>
                <span class="d-inline-block" v-else>
                  <span class="badge-purple height-22 mb-1">{{ data.item.tipo }}</span>
                  <span class="badge-gray height-22 mb-1" title="Faixa etária">
                    {{ data.item.faixa_etaria }}
                  </span>
                </span>
            </p>
        </template>

        <template v-slot:cell(detalhes)="data">
          <p class="h-100 d-flex align-items-end justify-content-end pl-">
            <b-button @click="showPartnerDetails(data)" variant="more-details" title="Exibir detalhes do sócio">
              <span class="d-inline d-xl-none d-xxl-inline mr-1">Detalhes</span>
              <i v-if="data.detailsShowing" class="bi bi-chevron-up ml-0"></i><i v-if="!data.detailsShowing" class="bi bi-chevron-down ml-0"></i>
            </b-button> 
          </p>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner><br>
            Carregando...
          </div>
        </template>  

        <template v-slot:row-details="data">
            <div class="company-details align-items-center pl-20 m-0">
                <div class="company-details-expanded">
<!-- se estrangeiro (remover índices) --><div class="mb-4" v-if="data.item.pais_ext">
                        <p class="label-details text-dark mb-1">Informações do país de origem</p>
                        <b-row class="row border border-color py-3 px-1 rounded-8 mx-0">
                            <b-col cols="6">
                                <p class="label-details text-medium-gray mb-0">País de Origem:</p>
                                {{ data.item.pais_ext | capitalize }}
<!-- adicionar país de origem -->    
                            </b-col>
                            <!-- código internacional (remover índice) -->
                            <!-- <b-col cols="6">
                                <p class="label-details text-medium-gray mb-0">Código Internacional:</p>
                                {{ data.item.cnpj_info[0][3] }}
                            </b-col> -->
                        </b-row>
                    </div>

                    <p class="label-details text-dark mb-1">Relação de empresas</p>
                    <div class="card card-details">
                        <b-table 
                        :items="data.item.cnpj_info"
                        :fields="fieldsTableParticipacoes"
                        class="table-optnd table-company-list">

                            <template v-slot:cell(cnpj)="data">
                                <span>{{ data.item[0] | fix-cnpj }}</span><br>
<!-- Adicionar razão social --><span> {{ data.item[6] | capitalize }} </span>
                            </template>

                            <template v-slot:cell(cod_qualificacao)="data">
<!-- converter o código de qualificação -->{{ data.item[2] }}<br>
                                <span class="text-small font-weight-500 text-medium-gray mb-0 text-uppercase">Entrada:</span>
                                {{ data.item[1] | fix-date }}
<!-- se possui representante (remover índice) --><div class="text-small bg-light-ciano w-100 rounded-8 border border-color p-2 mt-2" v-if="data.item[5]">
                                    <p class="label-details text-dark-ciano mb-0">Representante:</p>
                                    {{ data.item[4] | capitalize }}<br>
                                    {{ data.item[3] | fix-cpf }}<br>
                                    {{ data.item[5] }}
                                </div>
                            </template>
                        </b-table>                                                              
                    </div>
                </div>
            </div>
        </template>
     </b-table>
  </div>
  </div>
</template>



<script>

export default {
  props: {
    currentPage: Number,
    partners: Array  
  },
  data () {
    return {
      perPage: 25,      
      fields: [             
             { key: 'nome', label: 'Nome, <span class="font-weight-normal">CPF/CNPJ</span>', sortable: false },
             { key: 'detalhes', label: '', sortable: false },
      ],
      fieldsTableParticipacoes: [
          {key: 'cnpj', label: 'Raiz do CNPJ'},
          {key: 'cod_qualificacao', label: 'Qualificação'}, 
      ]      
    }
  },
  computed: {
    tableItems: {
      get() {
        return this.partners.map(partner => {
          return {
          ...partner,
          _showDetails: false
          }
      })
      },
      set(newItems) {
        return newItems
      }
    }
  },
  methods: {
    refresh() {
      this.$refs.brazilianCompaniesPartnersTable.refresh()      
    },
    showPartnerDetails(data) {      
      data.toggleDetails()
    },
    toggleRowDetailsById(ids) {
      if (this.$refs.brazilianCompaniesPartnersTable) {        
        var newItems = this.tableItems.map(item => {
          if (ids.includes(item.id)) {
            item._showDetails = true
          } else {
            item._showDetails = false
          }          
        })
        this.tableItems = newItems
      }
    }
  }
}
</script>
