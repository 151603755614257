import Vue from 'vue/dist/vue.esm';
import axios from 'axios';

// Import third party libs
import {BootstrapVue, BIcon, BIconQuestionCircleFill} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import LiquorTree from 'liquor-tree';
import { v4 as uuidv4 } from 'uuid';
import Numeral from 'numeral';
import "numeral/locales/pt-br";

// Vue initialization and globals
Vue.use(BootstrapVue, {
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'] // Adicionando breakpoints personalizados
});
Vue.component('BIcon', BIcon);
Vue.component('BIconQuestionCircleFill', BIconQuestionCircleFill);
Vue.use(LiquorTree);

window.Vue = Vue;
window.axios = axios;
window.uuidv4 = uuidv4;

Numeral.locale('pt-br');
window.numeral = Numeral;

// Import app-utilities
import {toTitleCase, fixCep, fixCnae, fixCnpj, fixCpf, fixDate, prettifyPhone,
        fixPhone, fixCapitalSocial, mapLookUp} from './utils/filters';

Vue.filter('capitalize', toTitleCase);
Vue.filter('fix-cep', fixCep);
Vue.filter('fix-cnae', fixCnae);
Vue.filter('fix-cnpj', fixCnpj);
Vue.filter('fix-cpf', fixCpf);
Vue.filter('fix-date', fixDate);
Vue.filter('fix-phone', fixPhone);
Vue.filter('prettify-phone', prettifyPhone);
Vue.filter('fix-capital-social', fixCapitalSocial);
Vue.filter('lookup', mapLookUp);
