// Todo: Organize this code, separate component-specific filters from global filters, comments

function pp_phone_only(phone) {
    return phone.split('').reverse().join('').replace(/(\d{4})(\d*)/, '$1-$2').split('').reverse().join('');
}

export function prettifyPhone(ddd_phone) {
    if ( ddd_phone.startsWith('0800') && ddd_phone.length >= 8 ) {
        return ddd_phone.replace(/(\d{4})(\d*)/, '$1-$2')    
    } else if ( ddd_phone.length > 11 && ddd_phone.startsWith('55') ) {
        const clean_phone = ddd_phone.replace(/^55/,'')
        return `(${clean_phone.slice(0, 2)}) ${pp_phone_only(clean_phone.slice(2))}`;
    } else if (ddd_phone.length > 9 && ddd_phone.length <= 11) {
        return `(${ddd_phone.slice(0, 2)}) ${pp_phone_only(ddd_phone.slice(2))}`;
    } else if (ddd_phone.length > 7) {
        return `${pp_phone_only(ddd_phone)}`;
    } else {
        return '';
    }
}

export function toTitleCase(str) {
    if ( str === undefined || str === null ) { return str; }
    else {
        return str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
}

export function fixCep(str) {
    if ( str === undefined || str === null ) { return ''; }
    else { return str.toString().slice(0,5) + "-" + str.toString().slice(5); }
}

export function fixCnae(str) {
    if ( str === undefined || str === null ) { return ''; }
    else {
        return str.toString().slice(0,4) + "-" +
            str.toString().slice(4,5) + "/" +
            str.toString().slice(5);
    }
}

export function fixCnpj(str) {
    if ( str === undefined || str === null ) { return ''; }
    else {
        return str.toString().slice(0,2) + "." +
            str.toString().slice(2,5) + "." +
            str.toString().slice(5,8) + "/" +
            str.toString().slice(8,12) + "-" +
            str.toString().slice(12,14);
    }
}

export function fixCpf(str) {
    if ( str === undefined || str === null ) { return ''; }
    else {
        return str.toString().slice(0,3) + "." +
            str.toString().slice(3,6) + "." +
            str.toString().slice(6,9) + "-" +
            str.toString().slice(9,11);
    }
}

export function fixDate(str) {
    if( str === undefined || str === null ) { return ''; }
    else {
        return str.toString().slice(8,10) + "/" +
            str.toString().slice(5,7) + "/" +
            str.toString().slice(0,4);
    }
}

export function fixPhone(str) {
    if ( str === undefined || str === null ) { return ''; }
    else {
        return str.toString().slice(0,-4) + "-" +
            str.toString().slice(-4);
    }
}

export function fixCapitalSocial(str) {
    if ( str === undefined || str === null ) { return ''; }
    else {
        return str.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + ",00";
    }
}

export function mapLookUp(str,mapping) {
    if ( str === undefined || str === null ) { return null; }
    else {
        var map = '';
        map = mapping.find(el => el.value === str);
        return map.text;
    }
}
