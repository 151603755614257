<template>
  <div class="card card-optnd card-cnpj">
  <b-table
      id="cnpj-table"
      class="table-optnd table-cnpj"
      ref="brazilianCompaniesTable"
      :items="fetchCompanies"
      :fields="filteredFields"
      :sort-by.sync="sortBy"
      :per-page="perPage"
      :current-page="currentPage"
      @refreshed="updateTable"
      empty-text="Nenhuma empresa encontrada"
      show-empty
      :selectable="selectable"
      no-select-on-click
      striped>

    <template v-if="selectable" v-slot:head(selected)="data">
      <b-form-group class="m-0 p-0 d-flex align-items-center">
        <b-form-checkbox size="md" v-model="anyRowSelected" @change="toggleAllRows"></b-form-checkbox>
      </b-form-group>
    </template>

    <template v-if="selectable" v-slot:cell(selected)="data">
      <b-form-group class="m-0 p-0 d-flex align-items-center">
        <b-form-checkbox size="md" v-model="data.rowSelected" @change="(checked)=>{rowCheckboxCallback(checked,data)}"></b-form-checkbox>
      </b-form-group>
    </template>

    <template #table-colgroup="scope">
      <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'detalhes' ? '136px' : field.key === 'selected' ? '40px' : ''}" >
    </template>
    <template #cell(id)="data">
      {{ data.index + 1 + (currentPage-1)*perPage }}
    </template>

    <template v-slot:head(nome)="data">
      <span v-html="data.label"></span>
    </template>
    <template v-slot:cell(nome)="data">
      <p class="mb-0">
        <span v-if="isFavoriteCompany(data.item.cnpj)" title="Empresa adicionada à lista Empresas Favoritas">
          <i class="bi bi-star-fill text-blue mb-0 mr-1"></i>
        </span>
        <a class="font-weight-500" :href="'/brazilian_companies/' + data.item.cnpj" target="_blank">{{ data.item.razao_social | capitalize }}</a>
      </p>
      <p class="mb-0 mt-1">
        <span v-if="data.item.nome_fantasia">{{ data.item.nome_fantasia | capitalize }}</span>
      </p>
      <p class="mb-0 mt-1 d-block d-xl-flex align-items-center">
        <span class="mr-2 text-nowrap">{{ data.item.cnpj | fix-cnpj}}</span>
        <span v-if="data.item.matriz_filial=='Matriz'" class="badge-purple height-22">{{ data.item.matriz_filial }}</span>
        <span v-else class="badge-pink height-22">{{ data.item.matriz_filial }}</span>
      </p>
    </template>

    <template v-slot:cell(cnae_fiscal)="data">
      <p class="font-weight-500 text-dark mb-1">{{ data.item.cnae_fiscal_pretty }}</p>
      <p class="mb-0">{{ data.item.cnae_text }}</p>
    </template>

    <template v-slot:cell(contato)="data">

      <div class="d-flex align-items-start justify-content-start">
        <span class="mr-2">
          <i class='bi bi-geo-alt-fill text-small text-medium-gray' title="Endereço"></i>
        </span>
        <span>
          <p class="mb-0">
            {{ data.item.tipo_logradouro | capitalize  }} {{ data.item.logradouro | capitalize  }} {{ data.item.numero }} {{ data.item.complemento | capitalize  }},
            <span v-if="data.item.bairro">{{ data.item.bairro | capitalize  }},</span> 
            <span v-if="data.item.bairro">CEP: <span class="text-nowrap">{{ data.item.cep | fix-cep }},</span></span>
            {{ data.item.municipio }} - {{ data.item.uf }}
          </p>
        </span>
      </div>

      <div v-if="hasContacts(data.item)" class="border-top border-color border-top-dashed pt-2 mt-2"> 

        <div v-if="hasContactType(data.item, 'phone')" class="d-flex align-items-center justify-content-start">
          <span class="mr-2">
            <i v-if="isMobilePhone(getFirstOfType(data.item,'phone'))" class="bi bi-whatsapp text-small text-medium-gray" title="WhatsApp"></i>
            <i v-else class='bi bi-telephone-fill text-small text-medium-gray' title="Telefone"></i>
          </span>
          <span>
            <a v-if="isMobilePhone(getFirstOfType(data.item,'phone'))" :href="whatsAppUrl(getFirstOfType(data.item,'phone'))" target="_blank">
              {{ getFirstOfType(data.item,'phone') | prettify-phone }}
            </a>
            <span v-else>{{ getFirstOfType(data.item,'phone') | prettify-phone }}</span>
            <b-badge pill v-if="countExtra(data.item,'phone')" @click="showCompanyDetails(data)" href="#" variant="extra-info">
              🞣 {{ countExtra(data.item,'phone') }}
            </b-badge>
          </span>
        </div>
        <div v-if="hasContactType(data.item, 'email')" class="d-flex align-items-center justify-content-start mt-1">
          <span class="mr-2">
            <i class='bi bi-envelope-fill text-small text-medium-gray' title="E-mail"></i>
          </span>
          <span class="word-break">
            <span v-if="emailTypoSuspect(data.item)"
                  :id="'typo_' + data.item.email"
                  @click="showCompanyDetails(data)" >
              {{ data.item.email.toLowerCase() }}<i class='bi bi-info-circle-fill text-orange ml-2'></i>
              <b-popover :target="'typo_' + data.item.email" triggers="hover" placement="bottom">
                É possível que este e-mail contenha um erro de digitação. Clique em <em>"Detalhes"</em> para ver sugestões de correção.
              </b-popover>
            </span>
            <span v-else>{{ getFirstOfType(data.item, 'email') | lowercase }}</span>
            <b-badge pill v-if="countExtra(data.item,'email')" @click="showCompanyDetails(data)" href="#" variant="extra-info">
              🞣 {{ countExtra(data.item,'email') }}
            </b-badge>
          </span>
        </div>
        <div v-if="hasContactType(data.item, 'url', 'website')" class="d-flex align-items-center justify-content-start mt-1">
          <span class="mr-2">
            <i class='bi bi-globe text-small text-medium-gray' title="Website"></i>
          </span>
          <span>
            <a :href="getWebsite(data.item)" target="_blank">
              {{ getWebsite(data.item) }}
            </a>
          </span>
          <b-badge pill v-if="countExtra(data.item,'url','website')" @click="showCompanyDetails(data)" href="#" variant="extra-info">
              🞣 {{ countExtra(data.item,'url','website') }}
            </b-badge>
        </div>
      </div>      
      <div v-if="getSocialNetworks(data.item).length > 0" class="social-network-links">
        <a v-for="(network, type) in getFirstSocialNetworks(getSocialNetworks(data.item))" 
          :key="type" v-if="network" 
          :href="addressableUrl(network.value)" 
          class="social-network-link"
          target="_blank">
          <i :class="socialNetworkIconClass[network.url_type] + ' social-network'"></i>
        </a> 
  
        <b-badge pill v-if="countExtraSocialNetworks(data.item,'url') > countDisplayedItems(getSocialNetworks(data.item))" 
          @click="showCompanyDetails(data)" 
          href="#" 
          variant="extra-info">
          🞣 {{ countExtraSocialNetworks(data.item,'url') - countDisplayedItems(getSocialNetworks(data.item)) }}
        </b-badge>
      </div>  
    </template>

    <template v-slot:cell(detalhes)="data">
      <div class="d-flex flex-column align-items-end justify-content-between h-100">
        <b-dropdown size="lg"  variant="cnpj-actions" toggle-class="text-decoration-none mb-3" menu-class="shadow-sm" right no-caret>
          <template #button-content>
            <i class="bi bi-three-dots-vertical"></i>
          </template>
          <b-dropdown-item v-for="list in recordLists">
            <a v-if="list.cnpjs.includes(data.item.cnpj)" @click.prevent="removeCnpjFromList(list.id, data.item.cnpj)">Remover da lista: {{ list.name }}</a>
            <a v-else @click.prevent="addCnpjToList(list.id, data.item.cnpj)">Adicionar à lista: {{ list.name }}</a>
          </b-dropdown-item>
          <b-dropdown-item :href="'/brazilian_companies/' + data.item.cnpj" target="_blank">Abrir ficha detalhada da empresa</b-dropdown-item>
          <b-dropdown-item @click="findCnpjConstructions(data.item.cnpj)">Pesquisar obras desta empresa</b-dropdown-item>
        </b-dropdown>

        <b-button @click="showCompanyDetails(data)" variant="more-details" class="mt-3" title="Exibir todos os detalhes da Empresa">
          Detalhes<i v-if="data.detailsShowing" class="bi bi-chevron-up"></i><i v-if="!data.detailsShowing" class="bi bi-chevron-down"></i>
        </b-button> 
      </div> 
    </template>

    <template #table-busy>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong> Buscando novas oportunidades de negócio para você </strong>
        <br/>
        Só mais uns segundinhos...
      </div>
    </template>   

    <template v-slot:row-details="data">
      
        <b-row class="company-details border-top border-color align-items-center pt-20">
          <b-col cols="6" lg="12" xl="6" class="pr-3 pl-0 pr-lg-0 pr-lg-20">
            <b-row class="m-0">
              <b-col cols="4" v-if="data.item.data_inicio_atividade" class="px-0">
                <div class="company-details-expanded">
                  <p class="label-details text-dark">Abertura</p>
                  <p class="mb-0">{{ data.item.data_inicio_atividade | fix-date }}</p>
                </div>
              </b-col>
              <b-col cols="4" v-if="data.item.data_situacao" class="px-0">
                <div class="company-details-expanded">
                  <p class="label-details text-dark">Última Atualização</p>
                  <p class="mb-0">{{ data.item.data_situacao | fix-date }}</p>
                </div>
              </b-col>
              <b-col cols="4" v-if="data.item.situacao_cadastral" class="px-0">
                <div class="company-details-expanded">
                  <p class="label-details text-dark">Situação Cadastral</p>
                  <span v-if="data.item.situacao_cadastral == 'Ativa'" class="badge height-22 badge-green">
                    {{ data.item.situacao_cadastral }}
                  </span>
                  <span v-else class="badge height-22 badge-gray">
                    {{ data.item.situacao_cadastral }}
                  </span>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" lg="12" xl="6" class="pl-3 pr-0 pl-lg-0 pl-lg-20">
            <div class="bg-white border border-color rounded-8 p-20">
              <h5 class="text-dark mb-1">Ficha completa da empresa</h5>
              <p class="mb-4">Veja as informações completas sobre esta empresa e faça pesquisas:</p>
              <a class="btn btn-blue mx-auto w-100" :href="'/brazilian_companies/' + data.item.cnpj" target="_blank">
                <i class="bi bi-eye mr-2"></i>Ver ficha da empresa
              </a>
            </div>
          </b-col>
        </b-row>
      
      
      <b-row  class="company-details py-20">
        <b-col cols="6" lg="12" xl="6" class="px-0">
          <div class="company-details-expanded pr-3 pr-lg-0 pr-lg-20">
            <p class="label-details text-dark mb-1">Contatos</p>

              <div class="card card-details">
                <BrCompaniesExtraContactsTable
                  v-model="data.item"
                  contact-source="receita">
                </BrCompaniesExtraContactsTable>
                <div v-if="noReceitaFederalContacts(data.item.extras)" class="p-20 text-center border-bottom border-color"> 
                  Empresa não possui contatos válidos registrados na base de dados da Receita Federal.
                </div>

                <BrCompaniesExtraContactsTable
                  v-model="data.item"                  
                  contact-source="other">
                </BrCompaniesExtraContactsTable>
                <div v-if="onlyReceitaFederalExtras(data.item.extras)" class="p-20 text-center">
                  <p>Empresa não possui contatos extras registrados. Busque novos contatos clicando no botão abaixo.</p>
                  <CnpjContactSearch
                    v-model="contactSearchResults"
                    :cnpj="data.item.cnpj"
                    :enricher-process-url="enricherProcessUrl">
                  </CnpjContactSearch>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="6" lg="12" xl="6" class="px-0">
            <div class="company-details-expanded pl-3 pl-lg-0 pl-lg-20">
              <p class="label-details text-dark mb-1">Quadro Societário</p>
              <div class="card card-details">
                <b-table 
                  :items="fetchSocios"
                  :fields="fieldsTableSocios"
                  class="table table-optnd table-company-list"
                  >

                  <template v-slot:cell(nome_socio)="socios_data">
                    <a v-if="partnerIsCompany(socios_data.item.cpf_cnpj)" :href="'/brazilian_companies/' + socios_data.item.cpf_cnpj" target="_blank">
                      {{ socios_data.item.nome_socio | capitalize }}
                    </a>
                    <span v-else>
                      {{ socios_data.item.nome_socio | capitalize }}
                    </span>                    
                  </template>

                  <template v-slot:cell(qualificacao)="socios_data">
                    {{ socios_data.item.qualificacao | capitalize }}<br>
                    <span class="text-small text-medium-gray mb-0 text-uppercase">Entrada:</span>
                    {{ socios_data.item.data_entrada | fix-date }}
                  </template>

                  <template v-slot:cell(socio_search)="socios_data">
                    <div class="d-flex align-items-center justify-content-end">
                      <div class="dropleft">
                        <button type="button" class="btn btn-cnpj-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="bi bi-three-dots-vertical"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right shadow">
                          <li>
                            <a role="button" 
                            @click="find_partner_companies(socios_data.item.nome_socio, socios_data.item.cpf_cnpj)" 
                            class="dropdown-item">
                            Pesquise todas as empresas deste sócio</a></li>
                        </div>
                      </div>
                    </div>
                  </template>
                </b-table>                                                              
                <div v-if="partnersLoading">
                  <div class="d-flex align-items-center justify-content-center p-20 h-100">
                    <b-spinner></b-spinner>
                  </div>
                </div>                
                <div v-else-if="!companiesPartnersPresent[data.item.cnpj]" class="d-flex align-items-center justify-content-center p-20 h-100">                  
                  <p class="mb-0">Empresa não possui quadro societário registrado</p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
    </template>
  </b-table>
  
</div>



</template>



<script>
import CnpjMapGoogle from './CnpjMapGoogle';
import CnpjContactSearch from './CnpjContactSearch';
import BrCompaniesExtraContactsTable from './BrCompaniesExtraContactsTable';

const socialNetworkIconClass = {"foursquare": "fa fa-foursquare",
                                "linkedin": "fa fa-linkedin",
                                "tripadvisor": "fa fa-tripadvisor",
                                "pinterest": "fa fa-pinterest",
                                "wordpress": "fa fa-wordpress-simple",
                                "spotify": "fa fa-spotify",
                                "whatsapp": "bi bi-whatsapp",
                                "facebook": "fa fa-facebook-square",
                                "instagram": "fa fa-instagram",
                                "twitter": "bi bi-twitter-x",
                                "youtube": "fa fa-youtube",
                                "twitch": "bi bi-twitch",
                                "tiktok": "bi bi-tiktok",
                                "website": "bi bi-globe"};

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}



export default {
  components: { CnpjMapGoogle, CnpjContactSearch, BrCompaniesExtraContactsTable },
  props: {
    excludeFields: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    },
    value: Array,
    recordLists: Array,
    currentPage: Number,
    internalReferrer: {
      type: Boolean,
      default: false
    },
    enricherProcessUrl: {
      type:String,
      default: 'http://localhost:8080/'
    },
    filterCnpjs: {
      type: Array,
      default: () => []
    },
    sorting: {
      type: Object,
      default: () => ({field: 'cnpj', order: 'asc'})
    },
    selectable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {      
      sortBy: 'nome',
      sortDesc: false,      
      perPage: 25,
      queryUrl: '/companies_data?',      
      fields: [
             { key: 'selected', label: '', sortable: false }, 
             { key: 'nome', label: 'Razão Social, <span class="font-weight-normal">Nome Fantasia</span>, <span class="font-weight-normal">CNPJ</span>', sortable: false },
             { key: 'cnae_fiscal', label: 'CNAE Fiscal', sortable: false },
             { key: 'contato', label: 'Endereço e Contatos', sortable: false },
             { key: 'detalhes', label: '', sortable: false, details_loaded: false}
      ],
      fieldsTableSocios: [
          {key: 'nome_socio', label: 'Nome do Sócio'},
          {key: 'qualificacao', label: 'Qualificação'},
          {key: 'socio_search', label: '', sortable: false, class: 'align-middle'}
      ],
      fieldsTableCnaesSecundarios: [
          {key:'cnae' , label:'CNAE'},
          {key:'description', label:'Denominação'}
      ],
      fieldsTableExtraContacts: [
          {key:'type' , label:'Tipo'},
          {key:'value', label:'Contato'},
          {key:'actions', label: ''}
      ],
      currentTableItems: [],
      rowData: [],
      tableErrorFlag: false,
      tableErrorResponse: {},
      currentCnpj: '',
      contactSearchResults: [],
      anyRowSelected: false,      
      socialNetworkIconClass,
      companiesPartnersPresent: {},
      partnersLoading: false
    }
  },
  computed: {
    filteredFields() {
      return this.fields.filter(field => !this.excludeFields.includes(field.key));
    }
  },
  watch: {
    contactSearchResults (newValue){      
      if (newValue.length > 0) {        
        const cnpj_row = this.$refs.brazilianCompaniesTable.localItems.find( (e) => {return e.cnpj == newValue[0].cnpj } )
        cnpj_row.extras = cnpj_row.extras.concat(newValue)
      }
    }    
  },
  methods: {
    refresh() {
      this.$refs.brazilianCompaniesTable.refresh()      
    },
    rowCheckboxCallback(checked, data) {
      checked ? data.selectRow() : data.unselectRow()      
      this.anyRowSelected = this.rowSelectedCheck()
      this.$emit('input', this.selectedCnpjs() )
    },
    toggleAllRows(checked) {
      checked ? this.$refs.brazilianCompaniesTable.selectAllRows() : this.$refs.brazilianCompaniesTable.clearSelected()
      this.$emit('input', this.selectedCnpjs() )
    },
    selectedCnpjs() {
      const table = this.$refs.brazilianCompaniesTable
      const cnpjs = table.localItems.map(item => item.cnpj)
      return cnpjs.filter((cnpj, index) => table.isRowSelected(index))
    },
    rowSelectedCheck() {
      if (this.$refs.brazilianCompaniesTable === undefined) return false
      const table = this.$refs.brazilianCompaniesTable      
      return [...Array(table.localItems.length).keys()].map((item,index)=>(table.isRowSelected(index))).some(e => {return e})      
    },
    markSelectedCnpjs() {
      if (this.$refs.brazilianCompaniesTable === undefined) return undefined;
      const table = this.$refs.brazilianCompaniesTable
      for (const [index, item] of table.localItems.entries()) {        
        if ( this.value.includes(item.cnpj) ) { table.selectRow(index) }
      }
    },
    updateTable() {
      this.$forceUpdate()      
    },
    fetchCompanies(ctx) {
      if (this.internalReferrer && this.filterCnpjs.length==0) {
        this.currentTableItems = []
        return []
      }      
      let newQuery = this.queryUrl 
                     + '&currentPage=' + ctx.currentPage
                     + '&perPage=' + ctx.perPage
                     + '&sortBy=' + ctx.sortBy
                     + '&sortDesc=' + ctx.sortDesc
                     + '&apiUrl=' + ctx.apiUrl
      if (this.internalReferrer) {
        newQuery += '&considerInternal=true'
      }      
      const promise = axios.get(newQuery)
      return promise.then(response => {        
        this.tableErrorFlag = false        
        let tableItems = this.orderTableItems(response.data.companies.data.map(item => item.attributes))
        let cnpjList = tableItems.map(item => item.cnpj)        
        return this.fetchExtras(cnpjList)
                   .then(extras => {
                       tableItems.forEach(item => {
                           item.extras = item.extras
                                             .concat( extras.filter( extra => extra.cnpj == item.cnpj ) )
                           item.extras.sort(this.compareExtras)
                       })
                       this.currentTableItems = tableItems                       
                       return tableItems
                   }).catch( error => { console.log(error)
                       throw( error ) })
      }).catch( error => {        
        if ( error.response.status == 503 && error.response.data.error.includes("Data source is updating now.") ) {          
          window.location.href = '/brazilian_companies/data_loading';          
        }
        this.tableErrorFlag = true
        this.tableErrorResponse = error.response
        console.error(error, error.stack)
        return []
      })
    },
    orderTableItems(items) {
      if (this.sorting.field=='data_inicio_atividade') {
        items.sort((a,b) => this.compareOpeningDates(a,b,this.sorting.order))
      }
      else {
        items.sort((a,b) => Number(a.cnpj) - Number(b.cnpj))
      }
      return items
    },
    compareOpeningDates(a,b,order) {
      if (order.toLowerCase().startsWith('asc')) {
        return new Date(a.data_inicio_atividade) - new Date(b.data_inicio_atividade)
      }
      else {
        return new Date(b.data_inicio_atividade) - new Date(a.data_inicio_atividade)
      }
    },    
    fetchCnaesSecundarios(ctx) {
      const newQuery = '/cnaes_secundarios_data?' + '&cnpj=' + this.currentCnpj
      const cnaes_secundarios_promise = axios.get(newQuery)
      return cnaes_secundarios_promise.then(response => {
        return response.data
      })
    },
    fetchSocios(ctx) {
      this.partnersLoading = true
      var newQuery = '/socios_data?' + '&cnpj=' + this.currentCnpj
      const socios_promise = axios.get(newQuery)
      return socios_promise.then(response => {
        this.companiesPartnersPresent[this.currentCnpj] = (response.data.length > 0)        
        this.partnersLoading = false
        return response.data
      })      
    },
    fetchExtras(cnpjList) {
      return axios.post('/enricher/enrich_cnpj_entries', {'cnpj': cnpjList})
                  .then(response => {
                    return response.data.data.map(item => item.attributes)
                  })
    },
    countExtra(dataItem, field, subfield=undefined) {
      let count = 0
      if ( subfield === undefined ) {
        dataItem.extras.forEach(extra => {        
          if ( extra.field_name == field ) { count++ }                
        })
      } else {
        dataItem.extras.forEach(extra => {        
          if ( extra.field_name == field && extra.url_type == subfield ) { count++ }                
        })
      }
      return Math.max(0,count-1)
    },    
    showCompanyDetails(data) {
      this.currentCnpj = data.item.cnpj
      data.toggleDetails()
    },
    emailTypoSuspect(dataItem) {
      const emails = dataItem.extras.filter(item => item.field_name == 'email')
      return emails.find(item => this.extraMessageIncludes(item, 'typo')) ? true : false
    },
    extraMessageIncludes(extraItem, query) {
      let sourceMessage = extraItem.source_message
      return ( sourceMessage && sourceMessage.includes(query) )
    },
    getWebsite(dataItem) {      
      const website = dataItem.extras.find(item => item.url_type == 'website')
      return website ? this.addressableUrl(website.value) : ''
    },
    getFirstSocialNetworks(socialNetworks) {
      const types = ['facebook', 'instagram', 'youtube', 'linkedin'];
      const result = {};
      types.forEach(type => {
        result[type] = socialNetworks.find(network => network.url_type === type);
      });
      return result;
    },
    countDisplayedItems(socialNetworks) {
      return Object.values(this.getFirstSocialNetworks(socialNetworks)).filter(Boolean).length;
    },
    getSocialNetworks(dataItem) {
      const urls = dataItem.extras
                           .filter(item => item.field_name === 'url' && item.url_type !== 'website')
      urls.forEach(item => { item.key = uuidv4() })
      return urls
    },
    countExtraSocialNetworks(dataItem, field) {
      let count = 0;
      dataItem.extras.forEach(extra => {
        if (extra.field_name === field && (!extra.url_type || extra.url_type !== 'website')) {
          count++;
        }
      });
      return count;
    },
    getGoogleSearchUrl(company, contact=false) {
      let name = company.nome_fantasia.length > 0 ? company.nome_fantasia : company.razao_social
      let base_url = 'http://www.google.com/search?q=' + this.prepareNameForSearch(name) + '+"' + company.municipio + '"+' + company.uf 
      if (contact) {
        return base_url + "+-CNPJ+telefone+email+contato"
      } else {
        return base_url + "+-CNPJ"
      }            
    },    
    prepareNameForSearch(nameStr) {            
      // Remove any possible CPF numbers at the end of the name
      nameStr = nameStr.trim().replace(/ \d{11}$/,'')
      // Escape ampersands
      nameStr = nameStr.replace(/&/,'%26')
      // Change to titlecase and return name in between double-quotes      
      return '"' + toTitleCase(nameStr) + '"'
    },    
    onlyReceitaFederalExtras(extras) {
      return extras === undefined || extras.length == 0 || extras.every( item => this.extraMessageIncludes(item, 'fromReceitaFederal') )
    },
    noReceitaFederalContacts(extras) {
      return !extras || extras.filter(item => item.source_message === 'fromReceitaFederal').length === 0;
    },
    getExtraSource(extraItem) {
      if (this.extraMessageIncludes(extraItem, 'fromReceitaFederal')) {
        return 'Receita Federal'
      } else if (this.extraMessageIncludes(extraItem, 'user_added')) {
        return 'Adicionado pelo Usuário'
      } else {
        return 'Oportunidados'
      }
    },
    compareExtras(a,b) {
      if ( b.field_name != a.field_name ) {
        return this.sortExtrasByFieldName(a,b)
      }
      else if ( this.extraMessageIncludes(b, 'typo') || this.extraMessageIncludes(a, 'typo') ) {
        return this.sortExtrasByMessage(a,b,'typo')
      }
      else if ( this.extraMessageIncludes(b, 'fromReceita') || this.extraMessageIncludes(a, 'fromReceita') ) {
        return this.sortExtrasByMessage(a,b,'fromReceita')
      }
      else if ( a.field_name == 'url' && b.url_type < a.url_type ) {
        return 1
      }
      return -1;
    },
    sortExtrasByFieldName(a,b) {
      const extraFieldNameOrder = ['email', 'phone', 'url']
      return extraFieldNameOrder.indexOf(a.field_name) - extraFieldNameOrder.indexOf(b.field_name)
    },
    sortExtrasByMessage(a,b,message) {
      if ( this.extraMessageIncludes(b, message) && !this.extraMessageIncludes(a, message) ) {
        return 1
      }
      else if ( !this.extraMessageIncludes(b, message) && this.extraMessageIncludes(a, message) ) {
        return -1
      }
      return 0
    },
    find_partner_companies (partner_name, partner_cnpj_cpf) {
      let partner_filter = {}
      if (this.partnerIsCompany(partner_cnpj_cpf)) {
        partner_filter = {cnpj_cpf_socio: partner_cnpj_cpf};
      } else {
        partner_filter = {nome_socio: partner_name, cnpj_cpf_socio: partner_cnpj_cpf};
      }
      axios.post('/brazilian_companies_filters/create_for_js', partner_filter)
           .then(response => {
             window.open('/brazilian_companies', '_blank').focus();                   
           }).catch(error => {
             alert("Ops! É possível que seu navegador esteja impedindo nosso aplicativo de abrir uma nova aba. Permita que nosso site abra novas abas e clique novamente no botão de busca.")
           })
    },
    partnerIsCompany(cnpj_cpf) {
      return cnpj_cpf.replaceAll(/\D/g,'').length == 14
    },
    isEpoch(dateString) {
      return dateString.slice(0,4)=='1970' && dateString.slice(6,8)=='01' && dateString.slice(10,12)=='01'
    },
    validDate(dateString) {
      return !(dateString === null) && dateString.length == 12 && !this.isEpoch(dateString)
    },
    isMobilePhone(phone) {            
      const regex = /(55)?(\d{2})?(\d{8,9})/
      const m = phone.match(regex) || []            
      return (m.length>0 && m[m.length-1].startsWith('9'))
    },
    whatsAppUrl(phone) {
      if (!phone.startsWith('55')) {
        phone = '55' + phone
      }
      return 'https://wa.me/' + phone
    },
    hasContacts(dataItem) {      
      return dataItem.extras.length > 0
    },
    hasContactType(dataItem, type, url_type=undefined) {
      if (url_type === undefined) {
        return dataItem.extras.filter(item => item.field_name == type).length > 0
      } else if (url_type === 'website') {
        return dataItem.extras.filter(item => (item.field_name == type && item.url_type == url_type)).length > 0
      } else {
        return dataItem.extras.filter(item => (item.field_name == type && item.url_type != 'website')).length > 0
      }
    },
    getFirstOfType(dataItem, type) {      
      const contacts = dataItem.extras.filter(item => item.field_name == type)
      return contacts.length > 0 ? contacts[0].value : ''
    },
    addCnpjToList(record_list_id, cnpj) {                     
      const path = "/record_lists/" + record_list_id + "/add_cnpjs"
      axios.post(path, {'cnpjs': cnpj}, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
           .then(response => {
             toastr.success(response.data.message)                                    
             this.updateRecordLists('add', record_list_id, cnpj)
           });
    },
    removeCnpjFromList(record_list_id, cnpj) {                     
      const path = "/record_lists/" + record_list_id + "/remove_cnpjs"
      axios.post(path, {'cnpjs': cnpj}, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
           .then(response => {
             toastr.warning(response.data.message)                  
             this.updateRecordLists('remove', record_list_id, cnpj)                  
           });                
    },
    updateRecordLists(action, list_id, cnpj) {
      this.recordLists.forEach( list => {
        if (list.id == list_id) {               
          if (action == 'add') {          
            list.cnpjs.push(cnpj)
          }
          else {                 
            list.cnpjs = list.cnpjs.filter( item => item != cnpj )
          }
        }
      })           
    },
    findCnpjConstructions(cnpj) {          
      const construction_filter = { tipo_responsavel:[1], ni_responsavel:[cnpj] };
      axios.post('/constructions_filters/create_for_js', construction_filter)
           .then(response => {
             window.open('/constructions', '_blank').focus();                   
           }).catch(error => {
             alert("Ops! É possível que seu navegador esteja impedindo nosso aplicativo de abrir uma nova aba. Permita que nosso site abra novas abas e clique novamente no botão de busca.")
           })
    },
    addressableUrl(url) {
      if (!url.startsWith('http')) {
        return 'http://' + url
      }
      return url
    },
    isFavoriteCompany(cnpj) {
      const favLists = this.recordLists.filter( list => (list.flags % 2) )
      return favLists.some( list => list.cnpjs.includes(cnpj) )      
    }
  },
  updated: function () {
    this.markSelectedCnpjs()
    this.anyRowSelected = this.rowSelectedCheck()    
  },
  filters: {
    lowercase(value) {
      return value.toLowerCase()
    },
  }
}
</script>
